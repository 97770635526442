import moment from "moment";
import "moment/locale/en-gb";
import PropTypes from "prop-types";
import React, { useState } from "react";
import DayPickerInput from "react-day-picker/DayPickerInput";
import MomentLocaleUtils, { formatDate, parseDate } from "react-day-picker/moment";
import Input from "./Input";
import InputField from "./InputField";
import InputReadOnly from "./InputReadOnly";

const InputCalendar = ({
  className,
  dayPickerProps,
  descriptionText,
  errors,
  id,
  isReadOnly,
  isRequired,
  labelText,
  disableDaysAfter,
  disableDaysBefore,
  name,
  onChange,
  placeholder,
  size,
  value,
  ...props
}) => {
  const [isRo, setIsRo] = useState(isReadOnly);
  const valueDate = value ? moment.utc(value).toDate() : undefined;

  return (
    <InputField
      className={className}
      descriptionText={descriptionText}
      errors={errors}
      iconName="calendar"
      id={id || name}
      isRequired={isRequired}
      labelText={labelText}
      name={name}
    >
      {!isRo && (
        <div className="relative">
          <DayPickerInput
            component={Input}
            dayPickerProps={{
              locale: "en-gb",
              localeUtils: MomentLocaleUtils,
              showOutsideDays: true,
              disabledDays: [{ after: disableDaysAfter, before: disableDaysBefore }],
              ...dayPickerProps,
            }}
            format="DD/MMM/YYYY"
            formatDate={formatDate}
            onDayChange={(date) => onChange(moment.utc(date).format(), name)}
            parseDate={parseDate}
            placeholder="DD/MMM/YYYY"
            value={valueDate}
            inputProps={{ name, errors }}
          />
        </div>
      )}

      {isRo && (
        <InputReadOnly
          {...props}
          key={value}
          defaultValue={value && moment.utc(value).format("DD/MMM/YYYY")}
        />
      )}
    </InputField>
  );
};

InputCalendar.propTypes = {
  className: PropTypes.string,
  dayPickerProps: PropTypes.object,
  descriptionText: PropTypes.string,
  errors: PropTypes.oneOfType([PropTypes.array, PropTypes.string, PropTypes.bool]),
  id: PropTypes.string,
  isReadOnly: PropTypes.bool,
  isRequired: PropTypes.bool,
  labelText: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  size: PropTypes.oneOf(["small", "normal"]),
  value: PropTypes.string,
};

InputCalendar.defaultProps = {
  className: undefined,
  dayPickerProps: {},
  descriptionText: undefined,
  errors: undefined,
  id: undefined,
  isReadOnly: undefined,
  isRequired: undefined,
  labelText: undefined,
  name: undefined,
  onChange: undefined,
  placeholder: undefined,
  size: "normal",
  value: undefined,
};

export default InputCalendar;
