export const CL = {
  CL355B: "cl355b",
  CL370: "cl370",
  CLAUSE01: "clause01",
  CLAUSE02: "clause02",
  CLAUSE03: "clause03",
  CLAUSE04: "clause04",
  CLAUSE05: "clause05",
  CLAUSE06: "clause06",
  CLAUSE06A: "clause06a",
  CLAUSE06B: "clause06b",
  CLAUSE07: "clause07",
  CLAUSE08: "clause08",
  CLAUSE09: "clause09",
  CLAUSE10: "clause10",
  CLAUSE11: "clause11",
  CLAUSE12: "clause12",
  CLAUSE13: "clause13",
  CLAUSE14: "clause14",
  JL2002002: "jl2002002",
  JL2002013: "jl2002013",
  JL2010005: "jl2010005",
  JL2020675A: "jl2020675a",
  WORDING1: "wording1", // JL2020 675A A&G&J amended / H deleted
  WORDING2: "wording2", // JL2020 675A A&G&J amended
  WORDING3: "wording3", // JL2020 675A G&J amended / H deleted
  WORDING4: "wording4", // JL2020 675A G&J amended
  WORDING5: "wording5", // JL2020 675A A&H deleted / G&J amended
  WORDING6: "wording6", // JL2020 675A A deleted / G&J amended
  LMA5389: "lma5389",
  LMA5390: "lma5390",
  LMA5395: "lma5395",
  LMA5403: "lma5403",
  LMA9184: "lma9184",
  LSW1022A: "lsw1022a",
  LSW553: "lsw553",
  USCANB: "uscanb",
};

export const CLAUSE_TYPES = ["wording", "exclusion", "condition", "warranty", "subjectivity"];

export default CL;
