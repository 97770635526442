import cloneDeep from "lodash.clonedeep";
import get from "lodash.get";
import set from "lodash.set";
import unset from "lodash.unset";
import moment from "moment";
import { compile } from "path-to-regexp";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";
import { ROUTES, STATUSES } from "../../constants";
import { parseParams } from "../../helpers/parseParams";
import * as api from "../api";
import useAuth from "./useAuth";

const prepareContractData = (contractData, isRenewalRoute, tenant) => {
  // https://trello.com/c/gludoqcz/186-producing-broker-logic-change
  if (Object.keys(contractData).length === 0 && tenant.type === "producing-broker") {
    const contractDataForProducing = cloneDeep(contractData);
    set(contractDataForProducing, "submission.broker_information.has_producing_agent", "Yes");
    set(contractDataForProducing, "submission.broker_information.producing_agent_id", tenant.id);
    return contractDataForProducing;
  }

  if (isRenewalRoute && process.env.DEFAULT_PRODUCT_REF !== "ah") {
    const contractDataForRenewal = cloneDeep(contractData);
    set(
      contractDataForRenewal,
      "submission.quote.inception_date",
      moment(get(contractDataForRenewal, "submission.quote.expiry_date"))
        .add(1, "day")
        .toISOString()
    );
    set(
      contractDataForRenewal,
      "submission.quote.expiry_date",
      moment(get(contractDataForRenewal, "submission.quote.inception_date"))
        .add(1, "year")
        .toISOString()
    );
    unset(contractDataForRenewal, "submission.payroll");

    return contractDataForRenewal;
  }

  return contractData;
};

const isLocalEnv = process.env.ENVIRONMENT === "local";
const isDemoEnv = process.env.ENVIRONMENT === "demo";

export const useContractQuery = (args) => {
  const queryClient = useQueryClient();
  const isNewContractRoute = useRouteMatch(ROUTES.CONTRACT_NEW);
  const isEditContractRoute = useRouteMatch(ROUTES.CONTRACT_EDIT);
  const isNewBespokeEndorsementRoute = useRouteMatch(ROUTES.ENDORSEMENT_BESPOKE_NEW);
  const isNewEndorsementRoute =
    useRouteMatch(ROUTES.ENDORSEMENT_NEW) || isNewBespokeEndorsementRoute;
  const isEditEndorsementRoute = useRouteMatch(ROUTES.ENDORSEMENT_EDIT);
  const isRenewalRoute = useRouteMatch(ROUTES.RENEWAL);

  const { push, location } = useHistory();
  const params = useParams();
  const queryParams = parseParams(location.search);
  const { productRef, contractId } = params;
  const endorsementId =
    (args && args.endorsementId) || queryParams.endorsementId || params.endorsementId;
  const enabled = Boolean(contractId);

  const productQuery = useQuery(["product", { productRef }], api.getProduct);
  const productData = get(productQuery, "data.data.data", {});

  const schemaId = get(productData, "schemaId");
  const schemaQuery = useQuery(["schema", { schemaId }], api.getSchema, {
    enabled: Boolean(schemaId),
  });
  const schemaData = get(schemaQuery, ["data", "data", "data", "payload"], {});

  const cKey = ["contract", { productRef, contractId }];
  const contractQuery = useQuery(cKey, api.getContract, { enabled });
  const contractData = get(contractQuery, "data.data.data", {});

  const endoKey = ["endorsement", { productRef, contractId, endorsementId }];
  const endoQuery = useQuery(endoKey, api.getEndorsement, {
    enabled: Boolean(contractId && endorsementId),
  });
  const endorsementData = get(endoQuery, "data.data.data");

  const endosKey = ["endorsements", { productRef, contractId }];
  const endorsementsQuery = useQuery(endosKey, api.getEndorsements, { enabled });
  const endorsementsData = get(endorsementsQuery, "data.data.data", []);

  const sKey = ["snapshot", { productRef, contractId }];
  const snapshotQuery = useQuery(sKey, api.getSnapshots, { enabled });
  const snapshotData = get(snapshotQuery, "data.data.data", []);

  const cclsKey = ["contractClauses", { productRef, contractId }];
  const clauseQuery = useQuery(cclsKey, api.getContractClauses, { enabled });
  const clauseData = get(clauseQuery, "data.data.data", []);

  const auKey = ["audit", { productRef, contractId }];
  const auditQuery = useQuery(auKey, api.getAudit, { enabled });
  const auditData = get(auditQuery, "data.data.data", []);

  const { mutateAsync: createContract, ...createContractQuery } = useMutation(api.createContract, {
    onSuccess: () => {
      queryClient.invalidateQueries("contracts", { productRef });
    },
  });

  const { mutateAsync: updateContract, ...updateContractQuery } = useMutation(api.updateContract, {
    onSuccess: (data) => {
      queryClient.setQueryData(["contract", { productRef, contractId }], data);
      queryClient.invalidateQueries("audit", { productRef, contractId });
      queryClient.invalidateQueries("contracts", { productRef });
      queryClient.invalidateQueries("endorsements", { productRef, contractId });
      setTimeout(() => {
        queryClient.resetQueries("documents", { productRef, contractId });
      }, 2000);
      setTimeout(() => {
        queryClient.resetQueries("snapshot", { productRef, contractId });
      }, 500);
    },
  });

  const { mutateAsync: createEndorsement, ...createEndorsementQuery } = useMutation(
    api.createEndorsement,
    {
      onSuccess: () => {
        queryClient.invalidateQueries("contract", { productRef, contractId });
      },
    }
  );

  const { mutateAsync: updateEndorsement, ...updateEndorsementQuery } = useMutation(
    api.updateEndorsement,
    {
      onSuccess: (data) => {
        queryClient.setQueryData(["endorsement", { productRef, contractId, endorsementId }], data);
        queryClient.invalidateQueries("audit", { productRef, contractId });
        queryClient.invalidateQueries("contract", { productRef, contractId });
        queryClient.invalidateQueries("contracts", { productRef });
        queryClient.invalidateQueries("endorsements", { productRef, contractId });
        setTimeout(() => {
          queryClient.resetQueries("documents", { productRef, contractId, endorsementId });
        }, 2000);
        setTimeout(() => {
          queryClient.resetQueries("snapshot", { productRef, contractId });
        }, 500);
      },
    }
  );

  const isLoading =
    auditQuery.isLoading ||
    clauseQuery.isLoading ||
    contractQuery.isLoading ||
    endoQuery.isLoading ||
    endorsementsQuery.isLoading ||
    productQuery.isLoading ||
    schemaQuery.isLoading ||
    snapshotQuery.isLoading;

  const { tenant } = useAuth();
  const currentContractData = endorsementId
    ? endorsementData
    : prepareContractData(contractData, isRenewalRoute, tenant);

  const is404 = get(contractQuery, "error.response.status") === 404;
  const isDraft = currentContractData?.status === STATUSES.DRAFT;
  const isCreating = createContractQuery.isLoading || createEndorsementQuery.isLoading;
  const isUpdating = updateContractQuery.isLoading || updateEndorsementQuery.isLoading;
  const hasEndorsements = endorsementsData.length > 0;
  const isEndorsement = Boolean(queryParams.endorsementId);
  const endoStatus = endorsementsData?.[0]?.status;

  const endoFinalStates = [
    STATUSES.APPLIED,
    STATUSES.REJECTED_NOT_TAKEN_UP,
    STATUSES.REJECTED_NOT_PROGRESSED,
    STATUSES.EXPIRED,
  ];

  const canCreateEndorsements =
    !hasEndorsements || (hasEndorsements && endoFinalStates.includes(endoStatus));

  const hasActiveEndorsements = hasEndorsements && !endoFinalStates.includes(endoStatus);

  if (productRef !== process.env.DEFAULT_PRODUCT_REF) {
    return push(compile(ROUTES.PRODUCT)({ productRef: process.env.DEFAULT_PRODUCT_REF }));
  }

  if (is404) {
    return push(compile(ROUTES.CONTRACT_NOT_FOUND)({ productRef }));
  }

  return {
    auditData,
    canCreateEndorsements,
    clauseData,
    contractData,
    contractId,
    createContract: !contractId || isRenewalRoute ? createContract : createEndorsement,
    currentContractData,
    endorsementData,
    endorsementId,
    endorsementsData,
    hasActiveEndorsements,
    hasEndorsements,
    isCreating,
    isDraft,
    isEditContractRoute,
    isEditEndorsementRoute,
    isEndorsement,
    isLoading,
    isNewBespokeEndorsementRoute,
    isNewContractRoute,
    isNewEndorsementRoute,
    isRenewalRoute,
    isUpdating,
    productData,
    productRef,
    schemaData,
    snapshotData,
    updateContract: endorsementId ? updateEndorsement : updateContract,
  };
};

export default useContractQuery;
