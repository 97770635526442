export const STATUSES = {
  APPLIED: "Applied",
  BOUND: "Bound",
  CANCELLED: "Cancelled",
  DECLINED: "Declined",
  DRAFT: "Draft",
  EXPIRED: "Expired",
  IDLE: "Idle",
  IN_PROGRESS: "In progress",
  QUOTED: "Quoted",
  REFERRED_QUOTED: "Referred Quoted",
  REFERRED: "Referred",
  REJECTED_NOT_PROGRESSED: "Not Progressed",
  REJECTED_NOT_TAKEN_UP: "Not Taken Up",
  RQ: "Referred Quoted",
};

export default STATUSES;
