import clsx from "clsx";
import React from "react";
import InputField from "./InputField";
import InputReadOnly from "./InputReadOnly";

const InputText = ({
  className,
  descriptionText,
  errors,
  formatter,
  id,
  isReadOnly,
  isRequired,
  labelText,
  link,
  max,
  min,
  name,
  onChange,
  onInput,
  pattern,
  step,
  type,
  value,
  ...props
}) => (
  <InputField
    className={className}
    descriptionText={descriptionText}
    errors={errors}
    id={id || name}
    isRequired={isRequired}
    labelText={labelText}
    link={link}
    name={name}
  >
    {isReadOnly && (
      <InputReadOnly
        {...props}
        aria-describedby={descriptionText ? `${name}Description` : undefined}
        name={name}
        value={value}
      />
    )}

    {!isReadOnly && (
      <input
        className={clsx("aui-input h-12", errors && "aui-input--errors")}
        id={id || name}
        max={max}
        min={min}
        name={name}
        onChange={onChange}
        onInput={onInput}
        pattern={pattern}
        required={isRequired}
        step={step}
        type={type}
        value={value || ""}
      />
    )}
  </InputField>
);

export default InputText;
