import clsx from "clsx";
import get from "lodash.get";
import PropTypes from "prop-types";
import React, { useState } from "react";
import Button from "./Button";
import InputField from "./InputField";

const InputFile = ({
  className,
  descriptionText,
  errors,
  isDisabled,
  isReadOnly,
  labelText,
  onChange,
  name,
  id,
  ...props
}) => {
  const [fileName, setFileName] = useState();

  const handleChange = (event) => {
    setFileName(get(event, "target.files[0].name"));

    if (onChange) {
      onChange(event);
    }
  };

  return (
    <InputField
      className={className}
      descriptionText={descriptionText}
      labelText={labelText}
      errors={errors}
      {...props}
    >
      <label htmlFor={id || name} className="block relative">
        <input
          className="sr-only"
          disabled={isDisabled}
          id={id || name}
          onChange={handleChange}
          type="file"
        />
        <div
          className={clsx(
            "aui-input aui-input-file h-12 flex items-center focus:outline-none focus:shadow-outline",
            isDisabled && "aui-input--disabled",
            isReadOnly && "aui-input--readonly",
            errors && "aui-input--errors"
          )}
          tabIndex="0"
          role="button"
        >
          <span className="truncate">{fileName}</span>
        </div>
        <div className="absolute flex items-center inset-y-0 right-0 mr-1">
          <Button className="pointer-events-none h-10" iconName="upload" isDisabled={isDisabled}>
            Browse
          </Button>
        </div>
      </label>
    </InputField>
  );
};

InputFile.propTypes = {
  className: PropTypes.string,
  descriptionText: PropTypes.string,
  errors: PropTypes.oneOfType([PropTypes.array, PropTypes.string, PropTypes.bool]),
  isDisabled: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  labelText: PropTypes.string,
  onChange: PropTypes.func,
  name: PropTypes.string,
  id: PropTypes.string,
};

InputFile.defaultProps = {
  className: undefined,
  descriptionText: undefined,
  errors: undefined,
  isDisabled: undefined,
  isReadOnly: undefined,
  labelText: undefined,
  onChange: undefined,
  name: undefined,
  id: undefined,
};

export default InputFile;
