import React from "react";

const Group = ({
  formValues,
  item,
  mapChildren,
  onChange,
  ownKey,
  setFormValues,
  validationErrors,
  ctx,
  showQuestionId,
}) => {
  return (
    <div>
      {mapChildren({
        parentSchema: item,
        formValues,
        parentKey: ownKey,
        onChange,
        setFormValues,
        validationErrors,
        ctx,
        showQuestionId,
      })}
    </div>
  );
};

export default Group;
