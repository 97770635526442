import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";
import InputField from "./InputField";

const InputTextarea = ({
  className,
  descriptionText,
  id,
  isRequired,
  labelText,
  name,
  onChange,
  value,
  errors,
}) => (
  <InputField
    className={className}
    descriptionText={descriptionText}
    id={id || name}
    isRequired={isRequired}
    labelText={labelText}
    name={name}
    errors={errors}
  >
    <textarea
      className={clsx("aui-input", "aui-input-textarea", errors && "aui-input--errors")}
      id={id || name}
      name={name}
      required={isRequired}
      value={value}
      onChange={onChange}
    />
  </InputField>
);

InputTextarea.propTypes = {
  className: PropTypes.string,
  descriptionText: PropTypes.string,
  labelText: PropTypes.string,
};

InputTextarea.defaultProps = {
  className: undefined,
  descriptionText: undefined,
  labelText: undefined,
};

export default InputTextarea;
