/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */

import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { KEY_CODES } from "../../constants";
import Card from "./Card";
import Icon from "./Icon";
import Loader from "./Loader";

const Header = ({ handleClose, children }) => (
  <Card.Header className="relative bg-gray-50 border-b border-gray-300 rounded-t">
    <div className="absolute top-0 right-0 mr-4 mt-4">
      <Icon
        className="w-6 h-6 p-1 hover:bg-gray-400 rounded-sm cursor-pointer fill-current text-gray-800"
        name="cross"
        onClick={handleClose}
        role="button"
        tabIndex="0"
      />
    </div>
    {children}
  </Card.Header>
);

const Modal = ({
  children,
  clearParamsOnClose,
  descriptionText,
  handleClose,
  headingText,
  isLoading,
}) => {
  const { location, push } = useHistory();

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.keyCode === KEY_CODES.ESCAPE) {
        handleClose(event);
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    document.body.classList.add("overflow-hidden");

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      document.body.classList.remove("overflow-hidden");
    };
  }, [handleClose]);

  useEffect(() => {
    return () => {
      if (clearParamsOnClose) {
        push({ pathname: location.pathname, search: "" });
      }
    };
  }, [location.pathname, push, clearParamsOnClose]);

  return (
    <div
      className="fixed inset-0 z-40 "
      onClick={handleClose}
      role="presentation"
      style={{ backgroundColor: "rgba(0, 0, 0, 0.4)" }}
      tabIndex="-1"
    >
      <div className="h-screen flex justify-center overflow-y-auto">
        <div
          className="z-30 w-1/2 max-w-3xl  self-start  mt-32 mb-32"
          style={{ minWidth: "500px" }}
          onClick={(event) => event.stopPropagation()}
        >
          <Card
            role="dialog"
            className="shadow-2xl"
            aria-labelledby="dialogTitle"
            aria-describedby="dialogDescription"
          >
            {(headingText || descriptionText) && (
              <Header handleClose={handleClose}>
                {headingText && (
                  <Card.Heading id="dialogTitle">
                    <div className="text-lg pl-2">{headingText}</div>
                  </Card.Heading>
                )}

                {descriptionText && (
                  <Card.Description id="dialogDescription">{headingText}</Card.Description>
                )}
              </Header>
            )}

            {isLoading && (
              <div className="flex items-center justify-center h-64">
                <Loader />
              </div>
            )}

            {!isLoading && children}
          </Card>
        </div>
      </div>
    </div>
  );
};

Header.propTypes = {
  children: PropTypes.node,
  handleClose: PropTypes.func.isRequired,
};

Header.defaultProps = {
  children: undefined,
};

Modal.propTypes = {
  clearParamsOnClose: PropTypes.bool,
  children: PropTypes.node,
  descriptionText: PropTypes.string,
  handleClose: PropTypes.func.isRequired,
  headingText: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
};

Modal.defaultProps = {
  children: undefined,
  descriptionText: undefined,
  headingText: undefined,
  clearParamsOnClose: false,
};

Modal.Content = Card.Content;
Modal.Description = Card.Description;
Modal.Header = Header;
Modal.Heading = Card.Heading;

export default Modal;
