import get from "lodash.get";
import PropTypes from "prop-types";
import React from "react";
import NumberFormat from "react-number-format";
import { currencyFormatter } from "../../formatters";
import Input from "./Input";
import InputField from "./InputField";
import InputReadOnly from "./InputReadOnly";

const InputCurrency = ({
  className,
  defaultValue,
  descriptionText,
  errors,
  id,
  isReadOnly,
  isRequired,
  labelText,
  name,
  noErrorLabel,
  onChange,
  value,
}) => {
  const currency = "USD";
  const amount = get(value, "amount");

  const handleChange = ({ floatValue }) => {
    if (onChange) {
      onChange(floatValue || floatValue === 0 ? { amount: floatValue, currency } : "", name);
    }
  };

  return (
    <InputField
      className={className}
      descriptionText={descriptionText}
      errors={errors}
      id={id || name}
      isRequired={isRequired}
      labelText={labelText}
      name={name}
      noErrorLabel={noErrorLabel}
    >
      {!isReadOnly && (
        <div className="relative">
          <div className="absolute inset-y-0 left-0 flex items-center justify-center w-8">$</div>

          <NumberFormat
            allowNegative={false}
            aria-describedby={descriptionText ? `${name}Description` : undefined}
            className="aui-input aui-input-currency"
            customInput={Input}
            decimalScale={0}
            defaultValue={defaultValue && defaultValue.amount}
            errors={errors}
            fixedDecimalScale
            id={name}
            isNumericString
            maxLength="22"
            name={name}
            onValueChange={handleChange}
            thousandSeparator
            value={amount}
            isRequired={isRequired}
          />
        </div>
      )}

      {isReadOnly && (
        <InputReadOnly
          key={JSON.stringify(value)}
          id={id || name}
          name={name}
          errors={errors}
          value={(value.amount || value.amount === 0) && currencyFormatter(value)}
        />
      )}
    </InputField>
  );
};

InputCurrency.propTypes = {
  className: PropTypes.string,
  defaultValue: PropTypes.object,
  descriptionText: PropTypes.string,
  errors: PropTypes.oneOfType([PropTypes.array, PropTypes.string, PropTypes.bool]),
  id: PropTypes.string,
  isReadOnly: PropTypes.bool,
  isRequired: PropTypes.bool,
  labelText: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.object,
};

InputCurrency.defaultProps = {
  className: undefined,
  defaultValue: undefined,
  descriptionText: undefined,
  errors: undefined,
  id: undefined,
  isReadOnly: undefined,
  isRequired: undefined,
  labelText: undefined,
  name: undefined,
  onChange: undefined,
  value: {},
};

export default InputCurrency;
