const total100 = {
  validate: (schema, data) => {
    const total = Object.keys(data).reduce((prev, next) => {
      return prev + data[next];
    }, 0);

    return total === 100;
  },
  errors: false,
};

export default total100;
