import get from "lodash.get";
import moment from "moment";
import React from "react";
import InputCalendar from "../InputCalendar";
import AHInputCalendarSchema from "./AHInputCalendarSchema";

const getDisabledDaysBefore = (schema, ctx) => {
  if ("ui:min:days:is:endorsement_effective_from" in schema) {
    const effectiveFromDate = get(ctx, "contractData.submission.quote.effective_from");

    if (effectiveFromDate) {
      return moment(effectiveFromDate).toDate();
    }
  }

  if ("ui:min:days:is:inception_date" in schema) {
    const inceptionDate = get(ctx, "contractData.submission.quote.inception_date");

    return moment(inceptionDate).toDate();
  }

  if ("ui:max:days:before:today" in schema) {
    return moment().subtract(schema["ui:max:days:before:today"], "days").toDate();
  }

  return undefined;
};

const getDisabledDaysAfter = (schema, ctx) => {
  if ("ui:max:days:is:expiry_date" in schema) {
    const expiryDate = get(ctx, "contractData.submission.quote.expiry_date");

    return moment(expiryDate).toDate();
  }

  if ("ui:max:days:after:today" in schema) {
    return moment().add(schema["ui:max:days:after:today"], "days").toDate();
  }

  return undefined;
};

const getInitialMonth = (schema, ctx) => {
  if ("ui:min:days:is:endorsement_effective_from" in schema) {
    const effectiveFromDate = get(ctx, "contractData.submission.quote.effective_from");

    if (effectiveFromDate) {
      return moment(effectiveFromDate).toDate();
    }
  }

  if ("ui:min:days:is:inception_date" in schema) {
    const inceptionDate = get(ctx, "contractData.submission.quote.inception_date");

    return moment(inceptionDate).toDate();
  }

  return undefined;
};

const InputCalendarSchema = ({ item: schema, ctx, ...props }) => (
  <InputCalendar
    disableDaysBefore={getDisabledDaysBefore(schema, ctx)}
    disableDaysAfter={getDisabledDaysAfter(schema, ctx)}
    dayPickerProps={{
      initialMonth: getInitialMonth(schema, ctx),
    }}
    {...props}
  />
);

export default process.env.DEFAULT_PRODUCT_REF === "ah"
  ? AHInputCalendarSchema
  : InputCalendarSchema;
