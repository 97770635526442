import moment from "moment";
import React from "react";
import InputCalendar from "../InputCalendar";

const getDisabledDaysBefore = (schema, ctx) => {
  if ("ui:min:days:is:endorsement_effective_from" in schema) {
    const effectiveFromDate = ctx?.contractData?.submission?.base?.effective_from;

    if (effectiveFromDate) {
      return moment(effectiveFromDate).toDate();
    }
  }

  if ("ui:min:days:is:inception_date" in schema) {
    const inceptionDate = ctx?.contractData?.submission?.base?.inception_date;

    return moment(inceptionDate).toDate();
  }

  if ("ui:max:days:before:today" in schema) {
    return moment().subtract(schema["ui:max:days:before:today"], "days").toDate();
  }

  return undefined;
};

const getDisabledDaysAfter = (schema, ctx) => {
  if ("ui:max:days:is:expiry_date" in schema) {
    const expiryDate = ctx?.contractData?.submission?.base?.expiry_date;

    return moment(expiryDate).toDate();
  }

  if ("ui:max:days:after:today" in schema) {
    return moment().add(schema["ui:max:days:after:today"], "days").toDate();
  }

  return undefined;
};

const getInitialMonth = (schema, ctx) => {
  if ("ui:min:days:is:endorsement_effective_from" in schema) {
    const effectiveFromDate = ctx?.contractData?.submission?.base?.effective_from;

    if (effectiveFromDate) {
      return moment(effectiveFromDate).toDate();
    }
  }

  if ("ui:min:days:is:inception_date" in schema) {
    const inceptionDate = ctx?.contractData?.submission?.base?.inception_date;

    return moment(inceptionDate).toDate();
  }

  return undefined;
};

const AHInputCalendarSchema = ({ item: schema, ctx, ...props }) => (
  <InputCalendar
    disableDaysBefore={getDisabledDaysBefore(schema, ctx)}
    disableDaysAfter={getDisabledDaysAfter(schema, ctx)}
    dayPickerProps={{
      initialMonth: getInitialMonth(schema, ctx),
    }}
    {...props}
  />
);

export default AHInputCalendarSchema;
