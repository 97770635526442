export const DMS = {
  UPLOAD_ALLOWED_FILE_TYPES:
    "jpg,jpeg,png,gif,tif,tiff,eps,psd,svg,txt,pdf,doc,docx,ppt,pptx,pps,ppsx,odt,odp,ods,xls,xlsx,csv,tsv",
  ALLOWED_DOCUMENT_TYPES:
    "attachment,message_attachment,submission_attachment,quote_document,policy_document,broker_invoice_document,broker_jacket_document,bound_endorsement_document,quote_endorsement_document",
  ALLOWED_DOCUMENT_SUBTYPES: "loss_run",
  ALLOW_REMOVING_DOCUMENT_TYPES: "attachment,message_attachment,submission_attachment",
  UNIQUE_DOCUMENT_TYPES:
    "quote_document,policy_document,broker_invoice_document,broker_jacket_document,bound_endorsement_document,quote_endorsement_document",
  DEFAULT_DOCUMENT_TYPE: "attachment",
};

export default DMS;
