const MODALS = {
  ATTACHMENTS: "AttachmentsModal",
  BESPOKE_EDIT: "EditBespokeModal",
  BIND_ENDORSEMENT_QUOTE: "BindEndoQuoteModal",
  BIND_QUOTE_SUCCESS: "BindQuoteSuccessModal",
  BIND_QUOTE: "BindQuoteModal",
  CANCEL_ENDORSEMENT: "CancelEndorsementModal",
  CHANGE_COMMISSION: "ChangeCommissionModal",
  CLAUSE_EDIT: "EditClauseModal",
  CREATE_CLAUSE: "CreateClauseModal",
  CREATE_ENDORSEMENT_WARNING: "CreateEndoWarningModal",
  CREATE_PRODUCT: "CreateProductModal",
  CREATE_SCHEMA: "CreateSchemaModal",
  DECLINE_CONTRACT: "DeclineContractModal",
  DOWNLOAD_BDX: "DownloadBdxModal",
  EDIT_QUOTE_WARNING_MODAL: "EditQuoteWarningModal",
  PROCESS_REFERRAL: "ProcessReferralModal",
  REFER_CONTRACT: "ReferContractModal",
  REJECT_NOT_PROGRESSED: "NotProgressedModal",
  REJECT_NOT_TAKEN_UP: "NotTakenUpModal",
  SUBMIT_BESPOKE_ENDORSEMENT: "SubmitBespokeEndorsementModal",
  SUBMIT_ENDORSEMENT_COMMERCIAL: "SubmitEndorsementCommercialModal",
  SUBMIT_ENDORSEMENT: "SubmitEndorsementModal",
  SUBMIT_RENEWAL_BESPOKE: "SubmitRenewalBespokeModal",
  UNSAVED_CHANGES: "UnsavedChangesModal",
};

export default MODALS;
